.about-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

.about-title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
}

.about-text {
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.text-style {
  margin-bottom: 20px;
}

@media (min-width: 778px) {
  .about-wrapper {
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 20px;
    margin: 100px 6.5% 25px 6.5%;
  }

  .about-title {
    width: 20%;
    font-size: 25px;
  }

  .about-text {
    width: 55%;
    font-size: 25px;
    line-height: 25px;
  }
}
